import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import styled from '@style'
import { Program, Benefits } from '../screens/Europe'
import { Hero, HeroTitle, ProgramDes } from '../screens/EuropeEN'

const StyledDiv = styled.div`
  width: 100%;
  backgroundcolor
`

const JoinUsEs = () => {
  return (
    <Layout>
      <SEO title="Únete a nosotros" />
      <HeroTitle
        subtitle="Inversión y Ahorro"
        titleProgram="Desde Aibrok Europe te ofrecemos soluciones de Inversión y Ahorro a medio y largo plazo, aportándote diversas alternativas y adaptando sus productos en función de tus necesidades."
        // email="info@aibrok.com.mx"
        suggestion="Ver abajo los tipos de productos que ofrecemos "
      />
      <>
        {/* <Hero
              titleProgram="Asesor financiero"
              joinUs="Únete a Nosotros"
            /> */}
        {/* <ProgramDes
          descriptionOne=" ¿Quieres desarrollar tu carrera en el sector Fintech, trabajando
              en una de las que está experimentando mayor crecimiento a nivel
              nacional?"
          descriptionTwo="   ¡En Aibrok te ofrecemos la oportunidad de construir una
              carrera exitosa, obteniendo una visión global del negocio con un
              programa de formación y desarrollo y un plan de carrera
              personalizado como Agente Financiero!"
        /> */}
        <Benefits />
      </>
      {/* <MyTabs
        labelTab2="Asesor financiero"
        labelTab="Agente Exclusivo"
        tabContent={
          <React.Fragment>
            <Hero
              titleProgram="Agente Exclusivo"
              joinUs="Únete a Nosotros"
            />
            <ProgramDes
              descriptionOne="¿Te gustaría trabajar en el sector, trabajando en una de las que está experimentando mayor crecimiento a nivel nacional e internacional?"
              subDescriptionOne="En Safebrok, empresa de referencia del sector, buscamos agentes colaboradores que aporten conocimientos, habilidades y experiencia en la gestión de clientes. Personas comprometidas, proactivas y con ganas de aprender y de aportar valor."
              descriptionTwo="Somos más de 175 personas en la entidad, con una red de 25
                oficinas."
              subDescriptionTwo="  Si quieres desarrollar tu carrera profesional en el sector
                financiero tecnológico, esta es tu oportunidad."
            />
            <Benefits />
          </React.Fragment>
        }
        tabContent2={
          <React.Fragment>
            <Hero
              titleProgram="Asesor financiero"
              joinUs="Únete a Nosotros"
            />
            <ProgramDes
              descriptionOne=" ¿Quieres desarrollar tu carrera en el sector Fintech, trabajando
              en una de las que está experimentando mayor crecimiento a nivel
              nacional?"
              descriptionTwo="   ¡En SafeBrok te ofrecemos la oportunidad de construir una
              carrera exitosa, obteniendo una visión global del negocio con un
              programa de formación y desarrollo y un plan de carrera
              personalizado como Agente Financiero!"
            />
            <Program />
          </React.Fragment>
        }
      /> */}
    </Layout>
  )
}

export default JoinUsEs
