import React from 'react'
import usersIcon from '@iconify/icons-feather/users'
import compressArrows from '@iconify/icons-uil/compress-arrows'
import pathfinderUnite from '@iconify/icons-uil/pathfinder-unite'
import arrowGrowth from '@iconify/icons-uil/arrow-growth'
import documentIcon from '@iconify/icons-uil/document'
import universityIcon from '@iconify/icons-uil/university'
import intershipIcon from '@iconify/icons-map/university'
import likeIcon from '@iconify/icons-ei/like'
import checkCircleFilled from '@iconify/icons-ant-design/check-circle-filled'
import outlineBusinessCenter from '@iconify/icons-ic/outline-business-center'
import machineLearningModel from '@iconify/icons-carbon/machine-learning-model'
import customerServiceTwotone from '@iconify/icons-ant-design/customer-service-twotone'

import styled from '@style'
import { Grid } from '@material-ui/core'
import { Box, Flex, Container } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'
import BgImg from '@images/benefits.png'
import ImgSearch from '@images/job_Offer.png'
import ValueCard from './ValueCard'
import ValueCardIcon from './ValueCardIcon'
import IconPage from './IconPage'

import SvgText from './SvgText'

const StyledBox = styled(Box)`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`
const Image = styled.img`
  overflow: hidden;
  height: 350px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`
const OurValues = () => (
  <Box>
    <Box as="section" bg="blue.500" py={2}>
      <Container>
        <SectionTitle>¿Qué ofrecemos?</SectionTitle>
        <Flex flexWrap="wrap" justifyContent="center">
          <ValueCard
            description="Formación y un programa de mentorship a medida para que puedas llegar a ser un gran profesional de las finanzas, con reuniones prácticas de seguimiento con el equipo comercial. "
            icon={usersIcon}
          />
          <ValueCard
            description="Posibilidad de consolidar tus conocimientos y adquirir una visión completa como agente  a través de un programa especializado y un plan de desarrollo con rotación con diferentes profesionales del sector."
            icon={compressArrows}
          />
          <ValueCard
            description="Herramientas y recursos que te ayudarán a conocer en profundidad la gestión patrimonial con el cliente."
            icon={pathfinderUnite}
          />
          <ValueCard
            description="Plan de carrera personal e individualizado, donde te guiaremos para conseguir tus objetivos."
            icon={arrowGrowth}
          />
        </Flex>
      </Container>
    </Box>
    <StyledBox as="section" bg="white" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="primary.900">
              ¿Qué beneficios tenemos para ti?
            </SectionTitle>
          </Grid>
          <Grid item container justify="center" xs={12} md={6}>
            <Image src={BgImg} />
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Trabajar con una de las empresas tecnológica-financiera de mayor tecnología avanzada y un equipo de profesionales cualificados."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
            <SvgText
              description="Formación continua desde el plan onboarding y durante tu plan de carrera todas las semanas."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
            <SvgText
              description="Formación MIFID II y seguros B."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
    <StyledBox as="section" bg="blue.500" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="primary.900">
              ¿Qué requisitos son necesarios para realizar este programa?
            </SectionTitle>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justify="center"
            xs={12}
            md={10}
            lg={8}
          >
            <IconPage
              description="Formación universitaria, preferiblemente en ADE, economía o similar."
              icon={documentIcon}
              fill="hsl(242, 51%, 19%)"
              color="#fff"
            />
            <IconPage
              description="Valorable contar con un máster o formación postgrado en sector banca/finanzas."
              icon={universityIcon}
              fill="hsl(242, 51%, 19%)"
              color="#fff"
            />
            <IconPage
              description="Posibilidad de formalizar convenio de colaboración de beca o contrato en prácticas."
              icon={intershipIcon}
              fill="hsl(242, 51%, 19%)"
              color="#fff"
            />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
    <StyledBox as="section" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="white">¿Qué estamos buscando?</SectionTitle>
            <Text mb={3} color="white" variant="h2">
              Estamos buscando personas como tú:
            </Text>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Con ganas de aprender y aportar valor."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Con formación y/o experiencia en productos de inversión."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Con perspicacia para los negocios, pasión por las ventas y un interés en los mercados financieros."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
            <SvgText
              description="Si prefieres enviarnos tu CV, contáctanos a través del email"
              email="info@aibrok.com.mx"
              color="white"
              fill="#fff"
            />
          </Grid>
          <Grid item container justify="center" xs={12} md={6}>
            <Image src={ImgSearch} />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
    <StyledBox as="section" bg="white" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="primary.900">
              ¿Qué competencias queremos que aportes?
            </SectionTitle>
          </Grid>

          <Grid item alignItems="center" xs={12}>
            <Flex flexWrap="wrap" justifyContent="center">
              <ValueCardIcon
                description="Alta capacidad de aprendizaje."
                icon={machineLearningModel}
                fill="hsl(242, 51%, 19%)"
              />
              <ValueCardIcon
                description="Fuertes habilidades analíticas, comerciales y de influencia."
                icon={outlineBusinessCenter}
                fill="hsl(242, 51%, 19%)"
              />
              <ValueCardIcon
                description="Orientación al cliente."
                icon={customerServiceTwotone}
                fill="hsl(242, 51%, 19%)"
              />
            </Flex>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  </Box>
)

export default OurValues
