import React from 'react'
import styled from '@style'
import { Grid } from '@material-ui/core'
import { Box, Flex, Container } from '@components/Grid'
import { SectionTitle } from '@components/Text'
import BgImg from '@images/benefits.png'
import ImgSearch from '@images/job_Offer.png'
import checkCircleFilled from '@iconify/icons-ant-design/check-circle-filled'
import likeIcon from '@iconify/icons-ei/like'
import SvgText from '../Program/SvgText'

const Image = styled.img`
  overflow: hidden;
  height: 350px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`
const StyledBox = styled(Box)`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

const CareerOpportunities = () => (
  <Box as="section" py={6}>
    <StyledBox as="section" bg="white" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="primary.900">Unit Linked</SectionTitle>
          </Grid>
          <Grid item container justify="center" xs={12} md={6}>
            <Image src={BgImg} />
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Puedes acceder como inversor a los mercados financieros nacionales e internacionales ya que desde Aibrok ponemos a tu disposición un amplio portfolio con las diferentes modalidades de Seguros Unit Linked que se pueden encontrar en las principales Compañías Aseguradoras del Mercado."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
    <StyledBox as="section" bg="primary.900" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="white">PIAS</SectionTitle>
          </Grid>

          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="
              Se trata de un Plan Individual de Ahorro Sistemático que te permite invertir en carteras de inversión según sea tu perfil de riesgo y obtener de este modo una atractiva rentabilidad, acumulando un capital y dando valor a un ahorro a largo plazo."
              color="white"
              fill="#fff"
              icon={checkCircleFilled}
            />
          </Grid>
          <Grid item container justify="center" xs={12} md={6}>
            <Image src={ImgSearch} />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
    <StyledBox as="section" bg="white" py={2}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12}>
            <SectionTitle color="primary.900">SIALP</SectionTitle>
          </Grid>
          <Grid item container justify="center" xs={12} md={6}>
            <Image src={BgImg} />
          </Grid>
          <Grid item container alignItems="center" xs={12} md={6}>
            <SvgText
              description="Se trata de un Seguro Individual de Ahorro a Largo Plazo en el que tendrás una exención fiscal total en los rendimientos que obtengas, siempre que no efectúes disposición alguna del capital resultante de tu póliza antes de finalizar el plazo mínimo de 5 años desde su apertura."
              fill="hsl(242, 51%, 19%)"
              side
              icon={likeIcon}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  </Box>
)

export default CareerOpportunities
