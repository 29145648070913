/* eslint-disable react/prop-types */
import React from 'react'
import styled from '@style'

import { Icon } from '@iconify/react'

import { Box } from '@components/Grid'
import { Text } from '@components/Text'

const StyledBox = styled(Box)`
  align-items: center;
  text-align: start;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    justify-content: center;
    text-align: center;
  }
`
const StyledRightBox = styled(Box)`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    justify-content: center;
    text-align: center !important;
  }
`
const StyledIcon = styled(Icon)`
  width: 50px;
  height: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 140px;
    height: 140px;
  }
`
const EmailLink = styled.a`
  transition: all 0.2s ease-in;
  text-decoration: none;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.white};
  &:hover {
    opacity: 0.8;
  }
`

const SvgText = ({ icon, description, color, fill, side, email }) => (
  <React.Fragment>
    {side ? (
      <StyledBox
        py={3}
        px={2}
        display={['block', 'flex']}
        data-aos="flip-right"
      >
        <Box px={4}>
          <StyledIcon icon={icon} color={fill} />
        </Box>
        <Text
          position="relative"
          zIndex="2"
          fontSize={4}
          as="h4"
          mb={0}
          color={color}
        >
          {description}
        </Text>
      </StyledBox>
    ) : (
      <StyledRightBox
        py={3}
        px={2}
        display={['block', 'flex']}
        data-aos="flip-left"
      >
        <Text
          position="relative"
          zIndex="2"
          fontSize={4}
          as="h4"
          mb={0}
          color={color}
          style={{ width: '100%' }}
        >
          {description}
          <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
        </Text>
        <Box px={4}>
          <StyledIcon icon={icon} color={fill} />
        </Box>
      </StyledRightBox>
    )}
  </React.Fragment>
)

export default SvgText
